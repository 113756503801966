import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
import { FaLocationArrow, FaPhone, FaRegEnvelope } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"kontakt"} />
		<Helmet>
			<title>
				Kontakt | Qyvyfiy Autozentrum
			</title>
			<meta name={"description"} content={"Qyvyfiy Autozentrum Centre ist nicht einfach nur eine Tankstelle, sondern ein Ort, an dem die Leidenschaft für Autos zum Leben erwacht."} />
			<meta property={"og:title"} content={"Kontakt | Qyvyfiy Autozentrum"} />
			<meta property={"og:description"} content={"Qyvyfiy Autozentrum Centre ist nicht einfach nur eine Tankstelle, sondern ein Ort, an dem die Leidenschaft für Autos zum Leben erwacht."} />
			<meta property={"og:image"} content={"https://qyvyfiy.com/img/home-1.jpeg"} />
			<link rel={"shortcut icon"} href={"https://qyvyfiy.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://qyvyfiy.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://qyvyfiy.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://qyvyfiy.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://qyvyfiy.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://qyvyfiy.com/img/icon.png"} />
			<meta name={"msapplication-TileColor"} content={"https://qyvyfiy.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="50px 0 20px 0" background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/16447966_v960-ning-05.jpg?v=2023-05-16T18:45:46.530Z) 0% 0% /cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Hero-9">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="--headline1"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--darkL1"
					lg-padding="0px 0 0px 0"
					as="h1"
				>
					Kontaktieren Sie uns
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="#50555a"
					padding="0px 200px 0px 200px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					Wenn Sie weitere Fragen haben, zögern Sie bitte nicht, uns zu kontaktieren. Wir geben Ihnen gerne Antworten und Ratschläge.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="fa"
						icon={FaLocationArrow}
						margin="0px 0px 22px 0px"
						color="--dark"
						size="36px"
					/>
					<Text margin="0px 0px 11px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Telefon
					</Text>
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--lead"
						text-align="center"
						flex="1 0 auto"
					>
						+49 331 74028208
					</Text>
				</Box>
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="fa"
						icon={FaPhone}
						margin="0px 0px 22px 0px"
						color="--dark"
						size="36px"
					/>
					<Text margin="0px 0px 11px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Anschrift
					</Text>
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--lead"
						text-align="center"
						flex="1 0 auto"
					>
						Lotte-Pulewka-Straße 4, 14473 Potsdam, Deutschland
					</Text>
				</Box>
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="fa"
						icon={FaRegEnvelope}
						margin="0px 0px 22px 0px"
						color="--dark"
						size="36px"
					/>
					<Text margin="0px 0px 11px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						E-Mail
					</Text>
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--lead"
						text-align="center"
						flex="1 0 auto"
					>
						info@qyvyfiy.com
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});